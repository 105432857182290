var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-gallery column" },
    [
      _vm.videos.length > 0
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "gallery-banner row d-space__between" },
                [
                  _c("img", {
                    attrs: { src: _vm.infoIcon },
                    on: { click: _vm.showTeacherPage }
                  }),
                  _c("div", { staticClass: "d-center" }, [
                    _c(
                      "span",
                      { key: _vm.video.id, staticClass: "video-tab__title" },
                      [_vm._v(_vm._s(_vm.video.title || ""))]
                    )
                  ]),
                  _c("div", { staticClass: "d-space__between" }, [
                    _c(
                      "span",
                      {
                        staticClass: "close-icon",
                        attrs: { "aria-hidden": "true" },
                        on: { click: _vm.goBack }
                      },
                      [_vm._v("×")]
                    )
                  ])
                ]
              )
            ]),
            _vm.videoSource
              ? [
                  _c("video-player", {
                    key: _vm.video.id,
                    attrs: {
                      id: _vm.video.id,
                      url: _vm.videoSource,
                      thumbnail: _vm.video.thumbnail,
                      width: 784,
                      height: 441
                    }
                  }),
                  _c("div", { staticClass: "d-flex col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn round primary",
                        on: { click: _vm.nextVideo }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("next")) + "\n        "
                        )
                      ]
                    )
                  ])
                ]
              : _vm._e()
          ]
        : _c("span", [_vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }